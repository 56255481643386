import PropTypes from 'prop-types'
import React, { useState } from 'react'
import algoliasearch from 'algoliasearch'
import Autocomplete from '@mui/material/Autocomplete'

import { TextField } from '@mui/material'

const propTypes = {
  alternative_medication: PropTypes.string.isRequired,
  organization_id: PropTypes.number.isRequired,
}

const AlternativeMedicationAutocomplete = ({
  alternative_medication,
  organization_id,
}) => {
  const [options, setOptions] = useState([])
  const [alternativeMedication, setAlternativeMedication] = useState(
    alternative_medication
  )

  const getOptionLabel = (option) => option

  const getDict = (query) => {
    const client = algoliasearch(
      'Q5ZBLQF81V',
      'df6aba0fce0130170bd701c1b4089506'
    )

    const queries = [
      {
        indexName: 'AthenaOrderCode',
        query,
        params: {
          hitsPerPage: 35,
        },
      },
      {
        indexName: 'AthenaOrderSet',
        query,
        params: {
          hitsPerPage: 5,
          filters: `organization_id=${organization_id}`,
        },
      },
    ]

    const searchCallback = (err, content) => {
      if (err) {
        console.error(err)
        return
      }
      const orderCodesResults = content.results[0]
      const orderSetsResults = content.results[1]

      if (
        orderCodesResults.hits.length > 0 ||
        orderSetsResults.hits.length > 0
      ) {
        const orderSetNames = orderSetsResults.hits.map((hit) => hit.name)
        const orderCodesNames = orderCodesResults.hits.map((hit) => hit.name)
        const newOptions = orderSetNames.concat(orderCodesNames)
        setOptions(newOptions)
      }
    }

    client.search(queries, searchCallback)
  }

  const handleChange = (event) => {
    const { value } = event.target
    getDict(value)
  }

  return (
    <>
      <Autocomplete
        id="alternative_medication"
        freeSolo
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleChange}
            variant="standard"
            label="Alternative Medication"
            sx={{ mt: -5 }}
          />
        )}
        value={alternativeMedication}
        filterSelectedOptions
        onChange={(_event, value, reason) => {
          if (reason === 'selectOption') {
            setAlternativeMedication(value)
          }
        }}
      />
      <input
        type="hidden"
        name="rule[alternative_medication]"
        value={alternativeMedication}
      />
    </>
  )
}

AlternativeMedicationAutocomplete.propTypes = propTypes

export default AlternativeMedicationAutocomplete
