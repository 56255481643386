import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Typography, Box } from '@mui/material'
import $ from 'jquery'

export default function ExternalRule() {
  const [file, setFile] = useState(undefined)
  const [selectedOption, setSelectedOption] = useState('')
  const [subscriptionOptions, setSubscriptionOptions] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState({})

  const handleOptionChange = (event) => {
    const { value } = event.target
    setSelectedOption(event.target.value)
    if (value === 'Default') {
      // Fetch default sources and title
      $.ajax({
        method: 'get',
        url: '/root/external_rule/external_rule_subscription_options',
        dataType: 'json'
      })
        .done((response) => {
          setSubscriptionOptions(response.externalRuleSubscriptions || [])
        })
        .fail((error) => {
          flash.error(`Error fetching default sources and title. ${error.responseJSON.errors}`, 5000)
          console.error(error)
        })
    } else {
      setSubscriptionOptions([])
    }
  }

  const handleFileSelect = (event) => {
    setFile(event.target.files[0])
  }

  const checkInput = () => {
    if (!file) {
      flash.error('Please select a file to upload', 5000)
      return false
    }
    if (!selectedOption) {
      flash.error('Please select an option', 5000)
      return false
    }
    return true
  }

  const handleFileSubmit = (event) => {
    event.preventDefault()
    if (!checkInput()) {
      return
    }
    const formData = new FormData()
    formData.append("file", file)
    formData.append("type", selectedOption)
    formData.append("subscription", JSON.stringify(selectedSubscription))
    $.ajax({
      method: 'post',
      url: '/root/external_rule/external_rule_csv',
      contentType: false,
      processData: false,
      data: formData,
      enctype: 'multipart/form-data'
    })
      .done(() => {
        flash.success('External rule csv successfully imported', 50000)

      })
      .fail((error) => {
        flash.error(`Error importing your External rule. ${error.responseJSON.errors}`, 50000)
        console.error(error)
      })
  }

  return (
    <>
      <form onSubmit={handleFileSubmit}>
        <Typography variant="h6">
          External rule import (csv)
        </Typography>
        <Box mb={2}>
          <FormControl margin='dense' variant='standard' sx={{ minWidth: 200 }} required>
            <InputLabel id="type-selection-label">Select import type</InputLabel>
            <Select 
              labelId="type-selection-label"
              id="type-selection"
              value={selectedOption} 
              onChange={handleOptionChange}
              margin="dense"
              variant="outlined"
              label="Select an option"
            >
              <MenuItem value="">Select an option</MenuItem>
              <MenuItem value="Default">Default</MenuItem>
              <MenuItem value="Stewart Risk Adjustment">Stewart Risk Adjustment</MenuItem>
              <MenuItem value="Privia GA Diabetes Bundle">Privia GA Diabetes Bundle</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {selectedOption === 'Default' && (
          <>
            <Box mb={2}>
              <Typography variant="subtitle1">
                Select Subscription
              </Typography>
              <FormControl margin='dense' variant='standard' sx={{ m: 1, minWidth: 200 }} required>
                <InputLabel id="subscription-selection-label">Select Subscription</InputLabel>
                <Select 
                  labelId="subscription-selection-label"
                  id="subscription-selection"
                  value={JSON.stringify(selectedSubscription)} 
                  onChange={(event) => setSelectedSubscription(JSON.parse(event.target.value))}
                  margin="dense"
                  variant="outlined"
                  label="Select a subscription"
                >
                  <MenuItem value="">Select a subscription</MenuItem>
                  {Array.isArray(subscriptionOptions) && subscriptionOptions.map((subscription, index) => (
                    <MenuItem key={`${subscription.source}-${index}`} value={JSON.stringify(subscription)}>
                      {`Title: ${subscription.title}, Source: ${subscription.source}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
        <Box mb={2}>
          <Typography variant="subtitle1">
            Select File
          </Typography>
          <input
            type="file"
            id="file-select"
            accept=".csv"
            onChange={handleFileSelect}
          />
        </Box>
        <button type="submit">
          Upload
        </button>
      </form>
    </>
  )
}