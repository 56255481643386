import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import SwitchAndAddRuleType from './switchAndAddRuleType'

const RuleTypeOptions = ({ ruleTypeOptions, ruleType, orderPreferences, orderSet, selections, targetValueSetName }) => {
  const [selectedRuleType, setSelectedRuleType] = useState(ruleType || null)

  const handleRuleTypeChange = (newRuleType) => {
    setSelectedRuleType(newRuleType)
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="rule-type-label">Rule Type</InputLabel>
        <Select
          labelId="rule-type-label"
          id="rule-type"
          value={selectedRuleType}
          label="Rule Type"
          onChange={(e) => handleRuleTypeChange(e.target.value)}
        >
          {ruleTypeOptions.map(([label, value]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(selectedRuleType === 'add' || selectedRuleType === 'switch') && (
        <SwitchAndAddRuleType
          orderPreferences={orderPreferences}
          orderSet={orderSet}
          selections={selections}
          targetValueSetName={targetValueSetName}
        />
      )}
      <input type="hidden" name="rule[rule_type]" value={selectedRuleType} />
    </div>
  )
}

RuleTypeOptions.propTypes = {
  ruleTypeOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ).isRequired,
  ruleType: PropTypes.string,
  orderPreferences: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      order_type: PropTypes.string,
      ordertypeid: PropTypes.string,
    })
  ).isRequired,
  orderSet: PropTypes.bool,
  selections: PropTypes.bool,
  targetValueSetName: PropTypes.string,
}

RuleTypeOptions.defaultProps = {
  ruleType: '',
  orderSet: false,
  selections: false,
  targetValueSetName: ''
}

export default RuleTypeOptions